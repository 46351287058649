import logo from './CRLogo.png';
import youtubeLogo from './youtubeicon.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="Header">
        <img className="logo" src={logo} />
        <h1>Chief River</h1>
      </div>
      <div className="subHeader">
        "Instructional IT Security videos to further the knowledge of those that seek to understand and further secure our digital world"
      </div>
      <div className="subHeader">
        <a href="https://www.youtube.com/channel/UClBQQRzLNKt9XSMe_GrVMEQ" target="_blank" > <img className="youtubelogo" src={youtubeLogo}/></a>
      </div>
      <div className="Videos">

        <h2>Spear Phishing - "One Click"</h2>
        <iframe title="Spear Phishing - One Click"src="https://drive.google.com/file/d/1R-kJzKGCObqR70DTpB41QuSiaSJKEigu/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Angler EK Analysis with Security Onion</h2>
        <iframe title="Angler EK Analysis with Security Onion"src="https://drive.google.com/file/d/1zEFuZsPjEXlU9JwB1UmxFIoELtPfhT8I/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Exposing Credentials in Group Policy Preferences Files with PowerSploit</h2>
        <iframe title="Exposing Credentials in Group Policy Preferences Files with PowerSploit" src="https://drive.google.com/file/d/1LQvuPzSEZTbjt-BdUnFxHaqNbsOEINMW/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>HackLab on Ravello Platform with SecurityOnion Sensor</h2>
        <iframe title="HackLab on Ravello Platform with SecurityOnion Sensor" src="https://drive.google.com/file/d/1t6Ki0s1VJzHXhUOutN_ohaGfIkxML_rA/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Meterpreter to Interactive Powershell then running PowerSploit Invoke-Mimikatz</h2>
        <iframe title="Meterpreter to Interactive Powershell then running PowerSploit Invoke-Mimikatz" src="https://drive.google.com/file/d/10fFeeREh0bLR6Lg0NuGDFk6jljOmxY-b/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>
      
        <h2>MOF + WMI + Powershell + Mimikatz = Auto Post Mimikatz data to webserver!</h2>
        <iframe title="MOF + WMI + Powershell + Mimikatz = Auto Post Mimikatz data to webserver!" src="https://drive.google.com/file/d/1tXjVw0NkN_PEM5d4eZyDdk2r11ebe2QW/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>OKTA Authentication via API (with MFA), then SAML federation analysis via browser web-tools</h2>
        <iframe title="OKTA Authentication via API (with MFA), then SAML federation analysis via browser web-tools" src="https://drive.google.com/file/d/1wDNVmUEakzH0MwzUtL217pQRxgtpPtW6/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Password Spraying a VPN (w_Burp Suite) - Why you need MFA on public user services</h2>
        <iframe title="Password Spraying a VPN (w_Burp Suite) - Why you need MFA on public user services" src="https://drive.google.com/file/d/15XC-Uhg9XxTqqz1ERgqwseXWwazghz3u/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>
 
        <h2>Password Spraying Active Directory</h2>
        <iframe title="Password Spraying Active Directory" src="https://drive.google.com/file/d/1eMEK1wph5yp93kvhLWs6ml5BbqUR3A6p/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Using Mandiant Redline to discover Meterpreter process injection</h2>
        <iframe title="Using Mandiant Redline to discover Meterpreter process injection" src="https://drive.google.com/file/d/1M90WMsdSOXjDcOQCNIHKh9rByaZnLZZw/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Using Metasploit BypassUAC exploit</h2>
        <iframe title="Using Metasploit BypassUAC exploit" src="https://drive.google.com/file/d/1HsUJsHZ7tSRgwuMLM5CEjzZe6B72kb-T/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Using PowerSploit Invoke-Shellcode w_Meterpreter Shellcode created by msfvenom</h2>
        <iframe title="Using PowerSploit Invoke-Shellcode w_Meterpreter Shellcode created by msfvenom" src="https://drive.google.com/file/d/1OmgIUgudqAfmTXAgDxZxQUkYO74-dTaC/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Using Veil-evasion Meterpreter exploit to bypass Anti-Virus</h2>
        <iframe title="Using Veil-evasion Meterpreter exploit to bypass Anti-Virus" src="https://drive.google.com/file/d/1KHMgnBymE5uNCfc-fC1jmyY4nTEtF6qB/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Windows 7 VM, Memory Analysis through .vmem file</h2>
        <iframe title="Windows 7 VM, Memory Analysis through .vmem file" src="https://drive.google.com/file/d/1CkmKD_xnXFPT0FTnyaxUq7EVgHBEd8nQ/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>
        
        <h2>Exposing Credentials in Group Policy Preferences Files with PowerSploit</h2>
        <iframe title="Windows 7 x64 Service Persistance Analysis using autostartsc" src="https://drive.google.com/file/d/12t7OyDIDhioEah4IqxarJ3ZU46Lp3Hcp/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>

        <h2>Windows 7 x64, Meterpreter Persistance (service)</h2>
        <iframe title="Windows 7 x64, Meterpreter Persistance (service)" src="https://drive.google.com/file/d/1oAO-s7lmsUQXhgsoAKDjAlKtHtjl1mE8/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>
        
        <h2>Windows 8.1 x64, Pulling Clear Text Passwords from Memory</h2>
        <iframe title="Windows 8.1 x64, Pulling Clear Text Passwords from Memory" src="https://drive.google.com/file/d/1UUFcHQtaP1xOGPDqMmyE-hQTRTG9VCFA/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>
        
        <h2>Windows Pivot Using Native netsh</h2>
        <iframe title="Windows Pivot Using Native netsh" src="https://drive.google.com/file/d/1eSTzIPcABvH_FjjbX3WRI6DIRv7RfufG/preview" width="80%" height="480" allow="autoplay" allowFullScreen></iframe>
      </div>
    </div>
  );
}

export default App;
